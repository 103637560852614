import React from "react";

import "../css/Footer.css";

// This is the template for each review
function Footer() {
  return (
    <div className="strip2">
        <p className="footerText">This product is in beta mode and is a proof of concept</p>
    </div>
  );
}
export default Footer;







